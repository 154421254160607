import { Routes } from '@angular/router';
import { InboundMetricsComponent } from './components/inbound-metrics/inbound-metrics.component';
import { NoAccessComponent } from './components/no-access/no-access.component';
import { CpRoutes } from './cp-route-constants';
export const routes: Routes = [
  {
    path: '',
    canActivate: [], //here AuthGuard will come
    loadComponent: () =>
      import(
        './components/realtime-dashboard/realtime-dashboard.component'
      ).then((c) => c.RealtimeDashboardComponent),
  },
  {
    path: 'auth',
    redirectTo: '',
    canActivate: [], //here AuthGuard will come
    loadComponent: () =>
      import(
        './components/realtime-dashboard/realtime-dashboard.component'
      ).then((c) => c.RealtimeDashboardComponent),
  },
  {
    path: CpRoutes.ReturnDetails,
    canActivate: [], //here AuthGuard will come
    loadComponent: () =>
      import('./components/return-metrics/return-metrics.component').then(
        (c) => c.ReturnMetricsComponent
      ),
  },
  {
    path: CpRoutes.RealtimeDashboard,
    canActivate: [], //here AuthGuard will come
    loadComponent: () =>
      import(
        './components/realtime-dashboard/realtime-dashboard.component'
      ).then((c) => c.RealtimeDashboardComponent),
  },
  {
    path: CpRoutes.ReportBuilder,
    canActivate: [], //here AuthGuard will come
    loadComponent: () =>
      import('./components/report-builder/report-builder.component').then(
        (c) => c.ReportBuilderComponent
      ),
  },
  {
    path: CpRoutes.OntimeShipSLA,
    canActivate: [], //here AuthGuard will come
    loadComponent: () =>
      import('./components/sla/summery-view/sla.component').then(
        (c) => c.SlaComponent
      ),
  },
  {
    path: CpRoutes.OntimeShipSLAList,
    canActivate: [], //here AuthGuard will come
    loadComponent: () =>
      import('./components/sla/list-view/list-view.component').then(
        (c) => c.ListViewComponent
      ),
  },
  {
    path: CpRoutes.HistoricDashboard,
    canActivate: [], //here AuthGuard will come
    loadComponent: () =>
      import(
        './components/historic-dashboard/historic-dashboard.component'
      ).then((c) => c.HistoricDashboardComponent),
  },
  {
    path: CpRoutes.InventoryDetails,
    canActivate: [], //here AuthGuard will come
    loadComponent: () =>
      import('./components/inventory-details/inventory-details.component').then(
        (c) => c.InventoryDetailsComponent
      ),
  },
  {
    path: CpRoutes.InventoryDetailsInfo,
    canActivate: [], //here AuthGuard will come
    loadComponent: () =>
      import(
        './components/inventory-details/inventory-detail-info/inventory-detail-info.component'
      ).then((c) => c.InventoryDetailInfoComponent),
  },
  {
    path: CpRoutes.PurchaseOrderDetails,
    canActivate: [], //here AuthGuard will come
    component: InboundMetricsComponent,
    // loadComponent:()=>{//load component here}
  },
  {
    path: CpRoutes.OrderDetails,
    canActivate: [], //here AuthGuard will come
    loadComponent: () =>
      import('./components/outbound-metrics/outbound-metrics.component').then(
        (c) => c.OutboundMetricsComponent
      ),
  },
  {
    path: CpRoutes.OrderDetailsInfo,
    canActivate: [], //here AuthGuard will come
    loadComponent: () =>
      import(
        './components/outbound-metrics/outbound-order-details/outbound-order-details.component'
      ).then((c) => c.OutboundOrderDetailsComponent),
  },
  {
    path: 'no-access',
    component: NoAccessComponent,
  },
  {
    path: '**',
    component: NoAccessComponent,
  },
];
