import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import {
  DialogService,
  DynamicDialogModule,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
@Component({
  selector: 'app-change-address-form',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    DynamicDialogModule,
    ButtonModule,
    InputTextModule,
    ReactiveFormsModule,
    FormsModule,
    DialogModule,
    FloatLabelModule,
    CommonModule,
  ],
  templateUrl: './change-address-form.component.html',
  styleUrl: './change-address-form.component.scss',
  providers: [DialogService, DynamicDialogRef],
})
export class ChangeAddressFormComponent {
  addressForm: FormGroup;
  isDisplay = false;
  private order: any;
  changeAddressTitle = 'Change address';
  customerNameLabel = 'Customer Name';
  newAddressLabel = 'Enter New Address';
  firstNameLabel = 'First name';
  firstNameValidatorFirst = 'First Name is required.';
  firstNameValidatorSecond = 'First Name must be at least 2 characters long.';
  firstNameValidatorThird =
    'First Name should only contain letters and spaces..';
  lastNameLabel = 'Last name';
  lastNameValidatorFirst = 'Last Name is required.';
  lastNameValidatorSecond = 'Last Name must be at least 2 characters long.';
  lastNameValidatorThird = 'Last Name should only contain letters and spaces..';
  addressLineOneLabel = 'Address line 1';
  addressLineOneValidatorsFirst = 'Address Line1 is required';
  addressLineOneValidatorsSecond = 'Minimum 3 characters are required.';
  addressLineTwoLabel = 'Address line 2';
  addressLineTwoValidatorsFirst = 'Minimum  characters are required.';
  postCodeLabel = 'Post Code';
  postCodeValidatorsFirst = 'Postal Code is required.';
  postCodeValidatorsSecond = 'Minimun 2 number is required';
  postCodeValidatorsThird = 'Post code should only contain numbers .';
  cityLabel = 'City';
  cityValidatorsFirst = 'City is required';
  cityValidatorsSecond = 'Minimum 2 character are required.';
  countryLabel = 'Country';
  countryValidatorsFirst = 'Country is required';
  countryValidatorsSecond = 'Minimum 2 character are required.';
  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router
  ) {
    this.addressForm = this.fb.group({
      firstName: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.pattern('[A-Za-z ]+'),
        ],
      ],
      lastName: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.pattern('^[a-zA-Z ]*$'),
        ],
      ],
      addressLine1: ['', [Validators.required, Validators.minLength(3)]],
      addressLine2: ['', [Validators.minLength(2)]],
      city: ['', [Validators.required, Validators.minLength(2)]],
      postcode: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.pattern('^[0-9]{5}$'),
        ],
      ],
      country: ['', [Validators.required, Validators.minLength(2)]],
    });
  }
  ngOnInit() {
    this.order = history.state?.order;
    if (this.order) {
      this.addressForm.patchValue({
        firstName: this.order.firstName || '',
        lastName: this.order.lastName || '',
        addressLine1: this.order.addressLine1 || '',
        addressLine2: this.order.addressLine2 || '',
        postcode: this.order.postcode || '',
        city: this.order.city || '',
        country: this.order.country || '',
      });
      this.isDisplay = true;
    }
  }
  onCancel() {
    this.router.navigate(['/orderDetails']);
  }
  onSubmit() {
    if (this.addressForm.valid) {
      this.router.navigate(['/orderDetails'], {
        state: { updatedOrder: this.addressForm.value },
      });
      this.isDisplay = false;
    }
  }
}
