<div class="grid-outer">
  <div class="breadcrumb">
    <div class="breadcrumb-inner">
      <div class="icon-sm home-svg"></div>
      <div class="welcome-outer">
        <span class="welcome-text">{{ welcomeLabel }}</span>
        <span class="icon-md arrow-svg"></span>
      </div>
      <div>
        <ul class="list">
          <li>
            <a href="#">{{ inboundPageTitle }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="page-title">
    <div class="text-left title-text">{{ inboundPageTitle }}</div>
  </div>
  <div class="paginator">
    <div class="text-right">
      <rcp-paginator
        *ngIf="!loading"
        [first]="first"
        [rows]="rows"
        [totalRecords]="totalRecords"
        (onPageChangeEvent)="onPageChange($event)"
        #paginator
      ></rcp-paginator>
      <rcp-paginator-skeleton *ngIf="loading"></rcp-paginator-skeleton>
    </div>
  </div>
</div>
<div class="flex flex-row flex-wrap gap-2 pt-0 p-4">
  <div class="input-box-with-icon input-box-width">
    <input
      type="text"
      class="input-box-search"
      [placeholder]="orderNumberPlaceholder"
      [(ngModel)]="selectedOrderNumber"
      (ngModelChange)="setOrderNumber()"
    />
    <span class="search-icon"></span>
  </div>
  <div class="input-box-with-icon input-box-width">
    <input
      type="text"
      class="input-box-search"
      [placeholder]="clientSkuPlaceholder"
      [(ngModel)]="selectedClientSku"
      (ngModelChange)="setClientSku()"
    />
    <span class="search-icon"></span>
  </div>
  <div class="input-box-width">
    <rcp-date-selector
      [calenderDateInputPlaceholder]="receivedDatePlaceholder"
      [isClearSearchData]="isClearLastReceivedDate"
      [maxDateOfFirstSelectionDate]="maxDateOfFirstSelectionReceivedDate"
      [maxDate]="maxReceivedDate"
      [isAddLast24HoursOption]="true"
      [isAddLast7DaysOption]="true"
      [isAddLast2WeeksOption]="true"
      [isAddLast30DaysOption]="true"
      [isAddLast90DaysOption]="false"
      [isAddLastYearOption]="false"
      [defaultStartDate]="defaultStartDate"
      [defaultEndDate]="defaultEndDate"
      (startDataEvent)="onStartDateSelectedOfReceivedDate($event)"
      (dateSearchEvent)="setLastReceivedDate($event)"
      (clearSearchDataEvent)="clearLastReceivedDate($event)"
    >
    </rcp-date-selector>
  </div>
  <div class="input-box-width">
    <rcp-date-selector
      [calenderDateInputPlaceholder]="interfaceDatePlaceholder"
      [isClearSearchData]="isClearInterfaceDate"
      [maxDateOfFirstSelectionDate]="maxDateOfFirstSelectionInterfaceDate"
      [maxDate]="maxInterfaceDate"
      [isAddLast24HoursOption]="true"
      [isAddLast7DaysOption]="true"
      [isAddLast2WeeksOption]="true"
      [isAddLast30DaysOption]="true"
      [isAddLast90DaysOption]="false"
      [isAddLastYearOption]="false"
      [defaultStartDate]="defaultInterfaceStartDate"
      [defaultEndDate]="defaultInterfaceEndDate"
      (startDataEvent)="onStartDateSelectedOfInterfaceDate($event)"
      (dateSearchEvent)="setInterfaceDate($event)"
      (clearSearchDataEvent)="clearInterfaceDate($event)"
    >
    </rcp-date-selector>
  </div>
  <div class="flex-1"></div>
  <div class="flex flex-row gap-2">
    <div>
      <button
        type="button"
        class="btn-secondary-lg"
        (click)="resetFiltersData()"
        [disabled]="loading"
      >
        {{ resetFiltersBtnLabel }}
      </button>
    </div>
    <div class="">
      <button
        type="submit"
        class="btn-primary-lg"
        [disabled]="
          loading ||
          (selectedReceivedDate?.length > 0 ||
          selectedInterfaceDate?.length > 0 ||
          selectedOrderNumber ||
          selectedClientSku
            ? false
            : true)
        "
        [tooltipDisabled]="
          !!(
            selectedReceivedDate?.length > 0 ||
            selectedInterfaceDate?.length > 0 ||
            selectedOrderNumber ||
            selectedClientSku
          )
        "
        [pTooltip]="disabledApplyBtnMsg"
        (click)="applyFilter()"
      >
        {{ applyFiltersBtnLabel }}
      </button>
    </div>
  </div>
</div>
<div class="main-grid pt-0 p-4">
  <rcp-grid
    [gridData]="gridData"
    [headerColumns]="headerColumns"
    [first]="first"
    [rows]="rows"
    [gridFilterAndSorting]="gridFilterAndSorting"
    [gridSorting]="gridSorting"
    [gridFilter]="gridFilter"
    [isRequiredStatus]="true"
    [statusIndex]="7"
    [loading]="loading"
    [gridSortData]="gridSortData"
    [gridFilterData]="gridFilterData"
  ></rcp-grid>
  <rcp-grid-skeleton *ngIf="loading"></rcp-grid-skeleton>
</div>
